@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"],
[data-color-scheme="blue"] {
  .block-with-links_block {
    background: transparent;
    border: 1px solid var(--gray-dark);
    &:not(:hover) {
      .block-with-links_block-icon {
        filter: brightness(0%);
      }
    }
    &:hover {
      background: var(--gray-dark) !important;
    }
  }
}
[data-color-scheme="blackWhite"] {
  .block-with-links_block {
    background: transparent;
    border: 1px solid var(--gray-dark);
    .block-with-links_block-icon {
      filter: brightness(0) invert(1);
    }
    &:hover {
      background: var(--gray-dark) !important;
      .block-with-links_block-icon {
        filter: brightness(0%);
      }
    }
  }
}

.block-with-links {
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_left {
    width: 486px;
    @include desktopStyle {
      width: get-vw(486px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
  }

  &_right {
    width: calc(100% - 520px);
    max-width: 945px;
    gap: 37px 20px;
    grid-gap: 37px 20px;
    @include desktopStyle {
      width: calc(100% - get-vw(520px));
      max-width: get-vw(945px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: none;
      margin-top: 32px;
      gap: 20px;
      grid-gap: 20px;
    }
    @media only screen and (max-width: $bp-phone) {
      gap: 12px;
      grid-gap: 12px;
    }
  }

  &_label {
    display: table;
    margin-top: 12px;
    vertical-align: middle;
    background: var(--blue);
    border-radius: 40px;
    position: relative;
    color: var(--white);
    padding: 1px 20px;
    @include desktopStyle {
      margin-top: get-vw(12px);
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 16px;
      font-size: 1.6rem;
      padding-left: 17px;
      padding-right: 17px;
    }
    &::after {
      content: "";
      position: absolute;
      display: block;
      background: var(--blue);
      border: 2px solid var(--white);
      width: 12px;
      height: 12px;
      right: 0;
      top: 0;
      filter: none !important;
      border-radius: 100%;
      @include desktopStyle {
        width: get-vw(12px);
        height: get-vw(12px);
        border-width: get-vw(2px);
      }
      @media only screen and (max-width: $bp-tablet) {
        width: 10px;
        height: 10px;
      }
    }
  }

  &_text {
    margin-top: 30px;
    color: var(--gray);
    @include desktopStyle {
      margin-top: get-vw(30px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 16px;
    }
  }

  &_block {
    background: var(--blue-light);
    transition: all 0.3s;
    width: calc(50% - 10px);
    position: relative;
    border-radius: 12px;
    padding: 40px 100px 40px 40px;
    color: var(--gray-dark);
    display: block;
    text-decoration: none;
    @include desktopStyle {
      padding: get-vw(40px) get-vw(100px) get-vw(40px) get-vw(40px);
    }
    @media only screen and (max-width: $bp-phone) {
      padding: 24px;
      width: calc(50% - 6px);
      border-radius: 8px;
    }
    &:hover {
      color: var(--white);
      .block-with-links_block-icon {
        animation: scaleIcon 0.6s ease-in-out;
        filter: brightness(0) invert(1);
      }
      @media only screen and (max-width: $bp-phone) {
        :global(.bg-white) {
          color: var(--white);
        }
      }
    }
    $colors:
      "blue" var(--blue),
      "pink_dark" var(--bg-pink-dark),
      "yellow" var(--bg-yellow),
      "green" var(--bg-green);
    @each $name, $bgBlock in $colors {
      &.bg-#{$name} {
        &:hover {
          background: $bgBlock;
        }
      }
    }
  }

  &_block-icon {
    width: 105px;
    height: 105px;
    display: block;
    @include desktopStyle {
      width: get-vw(105px);
      height: get-vw(105px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 48px;
      height: 48px;
    }
    img {
      display: block;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }

  &_block-text {
    font-family: $font-main-SB;
    font-size: 2.4rem;
    line-height: 1.5;
    display: block;
    margin-top: 43px;
    @include desktopStyle {
      margin-top: get-vw(43px);
    }
    @media only screen and (max-width: $bp-desktop-sm) {
      font-size: 2.2rem;
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 2rem;
      margin-top: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 24px;
      font-size: 1.6rem;
    }
  }

  &_block-link {
    position: absolute;
    display: block;
    right: 40px;
    bottom: 40px;
    @include desktopStyle {
      right: get-vw(40px);
      bottom: get-vw(40px);
    }
    @media only screen and (max-width: $bp-phone) {
      background: transparent;
      right: 24px;
      top: 24px;
      bottom: unset;
    }
    &:global(.bg-white) {
      &:hover {
        color: var(--gray-dark);
        border-color: var(--white);
        background-color: var(--white);
      }
      @media only screen and (max-width: $bp-phone) {
        background: transparent;
        color: rgba(var(--gray-light-rgba), 0.6);
        border: none;
        &:hover {
          background: transparent;
          color: var(--white);
        }
      }
    }
    &:global(.b-only-icon) {
      width: 48px;
      height: 48px;
      @include desktopStyle {
        width: get-vw(48px);
        height: get-vw(48px);
        svg {
          width: get-vw(16px);
          height: get-vw(16px);
        }
      }
      @media only screen and (max-width: $bp-phone) {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@keyframes scaleIcon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
